import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {CreateRecalculationJob} from './components/CreateRecalculationJob';
import {useDatesLogic} from "./hooks/useDatesLogic";
import {useHaActiveJobLogic} from "./hooks/useHasActiveJobLogic";


export function NewRecalculationJob() {
    const {
        fromDate, toDate, recalculatedBeforeDate,
        handleFromDateChange, handleToDateChange, handleRecalculatedBeforeDateChange
    } = useDatesLogic();
    const hasActiveJob = useHaActiveJobLogic();
    console.log(fromDate, toDate, recalculatedBeforeDate);
    return (
        <div>
            <Header>
                <h1>Create a new recalculation job!</h1>
                {hasActiveJob && (
                    <Warning>
                        Note: A recalculation job is currently running, and you won't be able to create a new one until
                        it's completed.
                    </Warning>
                )}
            </Header>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-from"
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        maxDate={toDate || undefined}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-to"
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        minDate={fromDate || undefined}
                        maxDate={new Date()}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd hh:mm:ss"
                    margin="normal"
                    id="date-picker-ignore-if-recalculated-after"
                    label="Ignore if recalculated after"
                    value={recalculatedBeforeDate}
                    onChange={handleRecalculatedBeforeDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    minDate={fromDate || undefined}
                />
            </MuiPickersUtilsProvider>
            <StartJobWrapper>
                <CreateRecalculationJob
                    fromDate={fromDate}
                    toDate={toDate}
                    recalculatedBeforeDate={recalculatedBeforeDate}
                    isDisabled={hasActiveJob}
                />
            </StartJobWrapper>
        </div>
    );
}

const StartJobWrapper = styled.div`
    max-width: 480px;
    margin: 20px auto;
`;

const Header = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

const Warning = styled.p`
    color: red;
`;
