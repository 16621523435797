import {useCallback, useMemo} from "react";
import {ParameterComparison} from "../../../../../../../../../api/types/ParameterComparison";
import {v4 as uuid} from "uuid";
import {GridCellParams} from "@mui/x-data-grid";
import {getCommonColumnProperties} from "../../../../../../../utils/tableUtils";
import {globalIdSortComparator, parameterValueFormatter, renderPositiveKey} from "../../../../../utils/tableUtils";

const renderWarning = (params: GridCellParams) => params.value === true ?
    <p style={{color: 'red'}}>Yes</p> :
    <p style={{color: 'green'}}>No</p>;


export function useTableLogic() {
    const columns = useMemo(() => {
        return [
            {field: 'globalId', width: 150, sortComparator: globalIdSortComparator},
            {field: 'locale', width: 150},
            {field: 'willBeUpdated', type: 'boolean'},
            {field: 'tooBigAlphaChange', type: 'boolean', renderCell: renderWarning},
            {field: 'tooBigKappaChange', type: 'boolean', renderCell: renderWarning},
            {field: 'strangeStatusChange', type: 'boolean', renderCell: renderWarning},
            {field: 'positiveKeyChanged', type: 'boolean', renderCell: renderWarning},
            {field: 'currentFacet', width: 150},
            {field: 'newFacet', width: 150},
            {field: 'currentStatus', width: 150},
            {field: 'newStatus', width: 150},
            {field: 'currentAlpha', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newAlpha', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa1', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa1', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa2', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa2', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa3', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa3', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa4', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa4', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentAlphaSigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newAlphaSigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa1Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa1Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa2Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa2Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa3Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa3Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentKappa4Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'newKappa4Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'currentPositiveKey', type: 'boolean', renderCell: renderPositiveKey},
            {field: 'newPositiveKey', type: 'boolean', renderCell: renderPositiveKey}
        ].map(cell => ({...getCommonColumnProperties(200), ...cell}));
    }, []);

    const getRows = useCallback((comparisonResults: ParameterComparison[]) => {
        return comparisonResults.map((comparison: ParameterComparison) => {
            return Object.assign({id: uuid()}, comparison)
        });
    }, []);

    return {columns, getRows};
}
